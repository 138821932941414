import React, { useEffect, useContext, memo } from 'react';
import { redirectWhenStatusPageIsNotValid, navigateToError } from '../../utils/navigate';
import { CreditcardContext } from '../../context/creditcard.context';
import { isGreaterThan, diffTwoDatesInSeconds as diff, isTrue, isUndefined } from '../../utils/functions';
import { Counter } from '../../components/counter';
import { FormNewCard } from '../../components/forms/new-card';
import { LoadingComponent } from '../../components/loading';
import Topbar from '../../components/responsive/topbar';
import Layout from '../../components/responsive/layout';
import AlertPopup from '../../components/alert-popup';
import { useStaticQuery, graphql } from 'gatsby';
import { LangContext } from '../../context/lang.context';

const RenderLayout = memo(
  ({ render, token, get, location }: any) => {
    const { t } = useContext(LangContext);
    const data = useStaticQuery(graphql`
      query {
        allI18NJson {
          nodes {
            locale
            clientId
            ALERTPOPUPS {
              MSG_NOT_CARD_ALLOWED
            }
          }
        }
      }
    `);

    const alertPopupText = t(data)?.ALERTPOPUPS.MSG_NOT_CARD_ALLOWED;

    if (!render) {
      return <LoadingComponent />;
    }
    return (
      <Layout>
        <Topbar />
        <div className="content relative">
          <Counter get={get} visible={false} height="0" paddingBottom="3" css={{ display: 'none' }} />
          {alertPopupText && <AlertPopup>{alertPopupText}</AlertPopup>}
          <FormNewCard token={token} location={location} showCardTypeInfo />
        </div>
      </Layout>
    );
  },
  ({ render: prev }, { render: next }) => prev === next
);

const AddPage = ({ getToken, location = {} }: PageProps) => {
  const { infoUser, timeStamp, getCard, loading } = useContext(CreditcardContext);
  const allValid = [Object.entries(infoUser).length, timeStamp?.length ?? 0].every(isGreaterThan(0));
  const token: string = getToken();

  useEffect(() => {
    if ((!infoUser?.expirationTime || !infoUser?.tokenExID) && token) {
      getCard?.(token);
    } else if (isUndefined(token)) {
      navigateToError()?.();
    }
  }, []);

  useEffect(() => {
    if (allValid) {
      redirectWhenStatusPageIsNotValid(infoUser, timeStamp);
    }
  }, [infoUser, timeStamp]);

  const dateDiff = diff(timeStamp ?? '', infoUser?.expirationTime ?? '');
  const render = [allValid, !!dateDiff, !infoUser.status, !loading].every(isTrue);
  return <RenderLayout render={render} token={token} location={location} />;
};

export default AddPage;
